//Colors

$dark-blue: #08324e;
$blue: #104a76;

$orange: #FF3300;
$light-blue: #17a3de;
$lighter-blue: #6ac7f1;

$first_color: $dark-blue;
$white_color: #ffffff;

//Color assignments

$primary-color: $orange;
$secondary-color: $dark-blue;

$primary-color-harman: $light-blue;
$primary-color-harman-kardon: $lighter-blue;

$header-links_color: $secondary-color;
$panelwraper_background-color: $secondary-color;
$footer_background-color: $secondary-color;
$primary-button_background-color: $primary-color;

$primary-button_background-color-harman: $primary-color-harman;
$primary-button_background-color-harman-kardon: $primary-color-harman-kardon;


/*//Colors #00a4e3

$dark-blue: #08324e;
$blue: #104a76;
$light-blue: #17a3de;
$lighter-blue: #6ac7f1;

//Color assignments

$secondary-color: $dark-blue;

$header-links_color: $secondary-color;
$panelwraper_background-color: $secondary-color;
$footer_background-color: $secondary-color;*/