@mixin lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/opensans/light/opensans-300',
    $font-weight: 300,
    $font-style: normal,
    $font-display: swap
) {
    @font-face {
        font-family: $family-name;
        src: url('#{$font-path}.eot');
        src: url('#{$font-path}.eot?#iefix') format('embedded-opentype'),
        url('#{$font-path}.woff2') format('woff2'),
        url('#{$font-path}.woff') format('woff'),
        url('#{$font-path}.ttf') format('truetype'),
        url('#{$font-path}.svg##{$family-name}') format('svg');
        font-weight: $font-weight;
        font-style: $font-style;
    }
}
@mixin lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/opensans/regular/opensans-400',
    $font-weight: 400,
    $font-style: normal,
    $font-display: swap
) {
    @font-face {
        font-family: $family-name;
        src: url('#{$font-path}.eot');
        src: url('#{$font-path}.eot?#iefix') format('embedded-opentype'),
        url('#{$font-path}.woff2') format('woff2'),
        url('#{$font-path}.woff') format('woff'),
        url('#{$font-path}.ttf') format('truetype'),
        url('#{$font-path}.svg##{$family-name}') format('svg');
        font-weight: $font-weight;
        font-style: $font-style;
    }
}
@mixin lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/opensans/semibold/opensans-600',
    $font-weight: 600,
    $font-style: normal,
    $font-display: swap
) {
    @font-face {
        font-family: $family-name;
        src: url('#{$font-path}.eot');
        src: url('#{$font-path}.eot?#iefix') format('embedded-opentype'),
        url('#{$font-path}.woff2') format('woff2'),
        url('#{$font-path}.woff') format('woff'),
        url('#{$font-path}.ttf') format('truetype'),
        url('#{$font-path}.svg##{$family-name}') format('svg');
        font-weight: $font-weight;
        font-style: $font-style;
    }
}
@mixin lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/opensans/bold/opensans-700',
    $font-weight: 700,
    $font-style: normal,
    $font-display: swap
) {
    @font-face {
        font-family: $family-name;
        src: url('#{$font-path}.eot');
        src: url('#{$font-path}.eot?#iefix') format('embedded-opentype'),
        url('#{$font-path}.woff2') format('woff2'),
        url('#{$font-path}.woff') format('woff'),
        url('#{$font-path}.ttf') format('truetype'),
        url('#{$font-path}.svg##{$family-name}') format('svg');
        font-weight: $font-weight;
        font-style: $font-style;
    }
}
@font-face {
    font-family: Gotham-Book;
    font-style: normal;
    margin-top: 0;
    line-height: 1.34;
    word-spacing: .07em;
    letter-spacing: .0015em;
    src        : url(../fonts/gotham/Gotham-Book.otf) format("truetype");
    font-display: swap;
}

@font-face {
    font-family: Gotham-Black;
    font-style: normal;
    margin-top: 0;
    line-height: 1.34;
    word-spacing: .07em;
    letter-spacing: .0015em;
    src        : url(../fonts/gotham/GOTHAM-BLACK.TTF) format("truetype");
    font-display: swap;
}

@font-face {
    font-family: Gotham-Bold;
    font-style: normal;
    margin-top: 0;
    line-height: 1.34;
    word-spacing: .07em;
    letter-spacing: .0015em;
    src        : url(../fonts/gotham/Gotham-Bold.ttf) format("truetype");
    font-display: swap;
}

@font-face {
    font-family: Univers-lt-std-black;
    font-style: normal;
    margin-top: 0;
    line-height: 1.34;
    word-spacing: .07em;
    letter-spacing: .0015em;
    src        : url(../fonts/universLTstd/Univers-LT-Std-75-Black_46351.ttf) format("truetype");
    font-display: swap;
}

@font-face {
    font-family: Univers-lt-std-light;
    font-style: normal;
    margin-top: 0;
    line-height: 1.34;
    word-spacing: .07em;
    letter-spacing: .0015em;
    src        : url(../fonts/universLTstd/Univers-LT-Std-47-Light-Condensed_46333.ttf) format("truetype");
    font-display: swap;
}

@font-face {
    font-family: Gotham-Bold-New;
    font-style: normal;
    margin-top: 0;
    line-height: 1.34;
    word-spacing: .07em;
    letter-spacing: .0015em;
    src        : url(../fonts/gothamNew/Gotham-Bold.otf) format("truetype");
    font-display: swap;
}

@font-face {
    font-family: Gotham-Black-New;
    font-style: normal;
    margin-top: 0;
    line-height: 1.34;
    word-spacing: .07em;
    letter-spacing: .0015em;
    src        : url(../fonts/gothamNew/Gotham-Black.otf) format("truetype");
    font-display: swap;
}

@font-face {
    font-family: MinionPro-Regular;
    font-style: normal;
    margin-top: 0;
    line-height: 1.34;
    word-spacing: .07em;
    letter-spacing: .0015em;
    src        : url(../fonts/gothamNew/MinionPro-Regular.otf) format("truetype");
    font-display: swap;
}

@font-face {
    font-family: Helvetica;
    font-style: normal;
    margin-top: 0;
    line-height: 1.34;
    word-spacing: .07em;
    letter-spacing: .0015em;
    src        : url(../fonts/gothamNew/Helvetica.ttc) format("truetype");
    font-display: swap;
}
//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    h1 {
        @include lib-css(font-size, $h1__font-size-desktop);
        @include lib-css(margin-bottom, $h1__margin-bottom__desktop);
    }
}

.items {
    @include lib-list-reset-styles();
}
