//
//  Common
//  _____________________________________________



input[type=datetime],
input[type=email],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type=text],
input[type=url],
select,
textarea {
    background-color: $white_color;
    background-clip: padding-box;
    border: 1px solid #c2c2c2;
    border-radius: 1px;
    box-sizing: border-box;
    font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
}

.field.choice,
.bk-radio-wrap {
    display: flex;
    gap: 8px !important;
    align-items: end !important;

    .label {
        margin: 0;
    }

}

.bank-types,
.card-types {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 10px;
    float: none;
    gap: 8px;

    input {
        position: relative !important;
        left: auto !important;
        order: -1;
        width: 20px;
        height: 25px !important;
        accent-color: $first_color;
        margin-top: auto !important;

        &:hover {
            accent-color: $first_color;

        }
    }
}

input[type='radio'] {
    position: relative !important;
    left: auto !important;
    order: -1;
    min-width: 20px;
    height: 25px !important;
    accent-color: $first_color;

    &:hover {
        accent-color: $first_color;

    }

    &:focus {
        box-shadow: none;
    }
}

.bk-payment-wrap {
    gap: 8px;
}

.payment-method-second-col {
    .fieldset {
        >.field {
            >.label {
                width: auto;
            }
        }
    }
}


.field.choice {
    .field-tooltip {
        float: right;
        top: -25px;
    }
}

input[type='checkbox']:not([required="true"]) {
    margin-right: 0;
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;

    +label {
        display: -webkit-box;
        position: relative;
        cursor: pointer;
        display: flex !important;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 8px;

        &:before {
            content: '';
            -webkit-appearance: none;
            background-color: transparent;
            border: 1px solid $first_color;
            padding: 8px;
            display: inline-block;
            position: relative;
            vertical-align: middle;
            cursor: pointer;
        }

        span {
            color: $first_color;
            display: block;
            width: calc(100% - 38px);

        }
    }

    &:checked+label:after {
        content: '';
        display: block;
        position: absolute;
        top: 2px;
        left: 6px;
        width: 4px;
        height: 10px;
        border: solid $white_color;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }

    &:checked+label:before {
        background: $first_color;
    }
}

div {


    input[type='checkbox'] {
        order: -1 !important;
        width: 5% !important;
        margin: 0;

        &[required="true"] {
            position: relative;
            border: 1px solid $first_color;
            background: none;
            cursor: pointer;
            line-height: 0;
            margin: 0 0.6em 0 0;
            outline: 0;
            padding: 0 !important;
            vertical-align: text-top;
            height: 18px;
            width: 18px !important;
            -webkit-appearance: none;
            border-radius: 0;

            &:checked {
                background-color: $first_color;
            }

            &:before {
                content: '';
                position: absolute;
                right: 50%;
                top: 50%;
                width: 4px;
                height: 10px;
                border: solid $white_color;
                border-width: 0 2px 2px 0;
                margin: -1px -1px 0 -1px;
                transform: rotate(45deg) translate(-50%, -50%);
                z-index: 2;
            }
        }
    }

    label:not([data-role="minisearch-label"]) {
        order: 0;
        width: 90%;
    }

    .mage-error {
        width: 100%;
        order: 1;
    }
}





//
//  Desktop
//  _____________________________________________

// @include min-screen($screen__m) {


//     [type="radio"]:checked+label:after,
//     [type="radio"]:not(:checked)+label:after {
//         content: '';
//         width: 10px;
//         height: 10px;
//         background: $first_color;
//         position: absolute;
//         top: 4.5px;
//         left: 4px;
//         border-radius: 100%;
//         -webkit-transition: all 0.2s ease;
//         transition: all 0.2s ease;
//     }
// }