//
//Layered navigation

.sidebar.sidebar-main {
	margin-bottom: 2rem;
	.filter-options {
        padding-bottom: 3rem;
	    .filter-options-content {
	        margin: 0 0 $indent__m;

	        .item {
	            margin-bottom: 3px;

	            .subcategorie {
	                list-style: none;
	                padding-top: 5rem;	                
	                margin-left: -1.7rem;
	                li {
	                    a {
	                        font-family: Univers-lt-std-light;
	                        color: white;
	                        font-size: 2.2rem;
	                        line-height: 1.3;
	                        &:hover {
	                        	text-decoration: none;
	                        	font-weight: 700;
	                        }
	                    }
	                }
	                .subitem.active {
	                    font-weight: 700;
	                }
	            }
	        }
	    }
	}
}
.category-harman,
.categorypath-harman-events,
.categorypath-harman-apperal,
.categorypath-harman-products,
.categorypath-harman-office,
.categorypath-harman-new,
.categorypath-harman-give-aways,
.categorypath-harman-ideabox {
	.logo {
		img {
			 content: url(../images/LogoHarman.png);
			width: 130px;
		}
	}
	.sidebar.sidebar-main {
		.block-content.filter-content.category-list {
			.filter-options {
                background: $primary-color-harman;
				.filter-options-content {
					.items {
						.item {
							&:nth-child(1) {							
								> a {
		                			display: none;
		            			}
	            			}
	            			&:nth-child(2) {
								display: none;
							}
							&:nth-child(3) {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}

.category-jbl,
.categorypath-jbl-events,
.categorypath-jbl-apperal,
.categorypath-jbl-products,
.categorypath-jbl-office,
.categorypath-jbl-new,
.categorypath-jbl-give-aways,
.categorypath-jbl-ideabox,
.categorypath-jbl-all,
.categorypath-jbl-jbl-exclusive {
    .logo {
        img {
            content: url(../images/LogoJBL.png);
            width: 81px;
        }
    }
	.sidebar.sidebar-main {
		.block-content.filter-content.category-list {
			.filter-options {
                background: $primary-color;
				.filter-options-content {
					.items {
						.item {
							&:nth-child(1) {															
		                		display: none;		            			
	            			}
	            			&:nth-child(2) {
								> a {
		                			display: none;
		            			}
							}
							&:nth-child(3) {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}

.category-harman-kardon,
.categorypath-harman-kardon-events,
.categorypath-harman-kardon-apperal,
.categorypath-harman-kardon-products,
.categorypath-harman-kardon-office,
.categorypath-harman-kardon-new,
.categorypath-harman-kardon-give-aways,
.categorypath-harman-kardon-ideabox {
	.logo {
		img {
			content: url(../images/LogoHarmanKardon.png);
			width: 130px;
		}
	}	
	.sidebar.sidebar-main {
		.block-content.filter-content.category-list {
			.filter-options {
                background: $primary-color-harman-kardon;
				.filter-options-content {
					.items {
						.item {
							&:nth-child(1) {															
		                		display: none;		            			
	            			}
	            			&:nth-child(2) {								
		                		display: none;		            		
							}
							&:nth-child(3) {
								> a {
		                			display: none;
		            			}
							}
						}
					}
				}
			}
		}
	}
}

.page-with-filter.catalogsearch-result-index {
    .page-wrapper {
        #maincontent {
            .column.main {
                width: 75%;        
            }
            .sidebar.sidebar-main {
                width: 25%;
                margin-bottom: 2rem;
                .filter-options {
                    padding-left: 2rem;
                    background: $secondary-color;
                    .filter-options-content {
                        margin: 0 0 $indent__m;

                        .item {
                            padding-top: 2.5rem;
                            padding-bottom: 2.5rem;
                            > a {
                                font-family: Univers-lt-std-light;
                                color: white;
                                font-size: 2.2rem;
                                font-weight: 700;
                                line-height: 1.3;
                            }

                            .subcategorie {
                                list-style: none;
                                padding-top: 3rem;
                                /*padding-bottom: 6rem;*/
                                margin-left: -1.7rem;
                                li {
                                    a {
                                        font-family: Univers-lt-std-light;
                                        color: white;
                                        font-size: 2.2rem;
                                        line-height: 1.3;
                                        &:hover {
                                            text-decoration: none;
                                            font-weight: 700;
                                        }
                                    }
                                }
                                .subitem.active {
                                    font-weight: 700;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// custom category view
//  -----------------------------------------
.catalog-category-view {
    .product-banner,
    .breadcrumbs {
        display: none;
    }
    .category-description {
        p {
            font-size: 1.7rem;
        }
    }
    @include min-screen($screen__m) {
        .product-item-inner {
            margin-top: -42px;
        }
    }
    .product-item-inner {
        margin-top: -33px;
    }
}


// custom product view
//  -----------------------------------------
.catalog-product-view {
    .category-banner {
        display: none;
    }
     @include min-screen($screen__m) {
	    #maincontent,
	    .breadcrumbs {
	    	padding-left: 8.5rem;
	    	padding-right: 8.5rem;
	    }
	}
}
.catalog-product-view,
.category-ideabox {
    .page-main {
        .product-info-main {
            .product-info-price {
                .product-info-stock-sku {
                    .stock {
                        display: none;
                    }
                }
            }
        }
    }
}
.product.info.detailed {
     @include min-screen($screen__m) {
        margin-bottom: 0;
    }
    .product.data.items {
        display: none;
    }
}
.fotorama__stage__frame {
    border: 2px solid #f2f2f2;
}

.product-info-main {
    .page-title-wrapper.product {
        display: inline-block;
        .page-title {
            font-size: 20px;
        }
    }

    .price-box.price-final_price {
        float: right;
        margin-top: 0;
        .price-container.price-final_price {
            .price-wrapper  {
                .price {
                    font-family: Univers-lt-std-light;
                    font-size: 2.2rem;
                }
            }
        }
    }

    .product.attribute.description {
        clear: both;

        .value {
            line-height: 2.5rem;
            color: #7d7d7d;
        }
    }

    .product-info-price {
        margin-top: 2rem;
       
        .product-reviews-summary.empty {
            display: none;
        }
    }
    .product-info-stock-sku {
        .stock {
            display: block;

            span {
                &:before {
                    content: "Availability: ";
                }
            }
        }
        
    }
    .product.attribute.sku {
        .type {
            display: none;
        }
        .value {
            text-transform: uppercase; 
            line-height: 2.9rem;

            &:before {
                content: "Article Number: ";
            }
        }
    }
    .stock-quantity {
        color: $secondary-color;
        &:after {
            content: "Detail ›";
            display: block;
            margin-top: 2rem;
            color: $secondary-color;
        }
    }
    .box-tocart {
        float: right;
        .fieldset {
          .field.qty {
            padding-right: 0;
            display: block;
            text-align: right;
            .label {
                display: inline-block;
                vertical-align: super;

                span {
                    text-transform: uppercase;
                    font-family: Univers-lt-std-light;
                }
            }
            .control {
                display: inline-block;
                .amount {
                    border: 1px solid darkgrey;
                    .input-text.qty.quantity {
                        width: 35px;
                        height: 25px;
                        padding: 0 10px 0 0;
                        margin-left: 10px;
                        text-align: center;
                        border: none;
                        border-right: 1px solid darkgrey;
                        vertical-align: super;
                    }
                    .button-set {
                        display: inline-block;

                        .btn.increase-btn,
                        .btn.decrease-btn {
                            padding: 0;
                            margin: 0 0.5rem 0 0;
                            width: 1rem;
                            height: 1rem;                            
                            display: block;
                            background: transparent;
                        }
                    }
                }
            }
          }
          .actions {
            padding-top: 0;
            }
        }
    }
    .product-social-links {
        display: none;
    }
}
 @include min-screen($screen__m) {
    .product.media {
        margin-bottom: 1rem;
    }
}

.catalog-product-view.category-harman,
.catalog-product-view.categorypath-harman-events,
.catalog-product-view.categorypath-harman-apperal,
.catalog-product-view.categorypath-harman-products,
.catalog-product-view.categorypath-harman-office,
.catalog-product-view.categorypath-harman-new {
    .box-tocart {
        .action.primary.tocart {
            @include primary-button($primary-button_background-color-harman);
        }
    }
    @include min-screen($screen__m) {
	    .fotorama__caption {
	        &:after {
	            content: url(../images/InzoomenHarman.png);
	        }
	    }
	}
}

.catalog-product-view.category-jbl,
.catalog-product-view.categorypath-jbl-events,
.catalog-product-view.categorypath-jbl-apperal,
.catalog-product-view.categorypath-jbl-products,
.catalog-product-view.categorypath-jbl-office,
.catalog-product-view.categorypath-jbl-new {
    .box-tocart {
        .action.primary.tocart {
            @include primary-button($primary-button_background-color);
        }
    }
}

.catalog-product-view.category-harman-kardon,
.catalog-product-view.categorypath-harman-kardon-events,
.catalog-product-view.categorypath-harman-kardon-apperal,
.catalog-product-view.categorypath-harman-kardon-products,
.catalog-product-view.categorypath-harman-kardon-office,
.catalog-product-view.categorypath-harman-kardon-new {
    .box-tocart {
        .action.primary.tocart {
            @include primary-button($primary-button_background-color-harman-kardon);
        }
    }
}

.block.related {
    .block-title.title {
        #block-related-heading {
            text-transform: uppercase;
        }
    }
    .block-content.content {
        .block-actions {
            display: none;
        }
        .products.wrapper.grid.products-grid.products-related {
        	.products.list.items.product-items {
        		.product-item-info.related-available {
        			width: 180px;

        			.product.details.product-item-details {
        				display: none;
        			}
        		}
        	}
        }
    }
}

.page-title,
.price-wrapper,
.stock.available,
.label  {
    span {
        color: $secondary-color;
    }
}

@include max-screen($screen__m) {
    .product-info-main {
        margin-top: 3.5rem;
        .box-tocart {
            float: none;
        }
    }
    .block.related {
        .block-title.title {
            text-align: center;
        }
        .block-content.content {
            .products.wrapper.grid.products-grid.products-related {
                .products.list.items.product-items {
                    .product-item-info.related-available {
                        margin: 0 auto;
                        margin-bottom: 2rem;
                    }
                }
            }
        }
    }
}

//-------------------------
.fotorama__caption {
    text-align: right;
    .fotorama__caption__wrap {
        display: none;
    }
}


// Minicart and Cart
//------------------------------------------------
.minicart-wrapper .action.showcart .counter.qty {
    background: $secondary-color;
}

.block-minicart .block-content > .actions > .primary .action.primary {
    padding: 14px 17px;
}

.block.block-minicart.ui-dialog-content.ui-widget-content {
    top: auto;
}

.minicart-items {
    .product-item-name {
        a {
            color: $secondary-color;            
        }
    }
    .product-item-details {
        .details-qty {
            margin-right: 6.5rem;
        }
    }
}

.checkout-cart-index {
    .cart-container {
        .actions-toolbar {
            .action.towishlist.action-towishlist {
                @include primary-button($secondary-color);
                padding: 1.5rem;
            }
            .action.action-edit,
            .action.action-delete {
                @include primary-button($secondary-color);
                padding: 1rem;
                :hover {
                    background: $secondary-color;
                    opacity: .8;
                }   
            }
            .action.action-edit {
                content: url(../images/edit-7-24.ico);
            }
            .action.action-delete {
                content: url(../images/delete-24.ico);
            }
        }
        .form-cart {
            .cart.main.actions {
                .action.continue,
                .action.clear,
                .action.update {
                    background: transparent;
                    color: $secondary-color;
                    font-family: Univers-lt-std-black;
                    border: none;
                }
            }
        }
    }
    .cart-container .checkout-methods-items .action.primary {
    	padding: 14px 17px;
    }
}


// Checkout
//------------------------------------------------

.checkout-index-index {
	 @include max-screen($screen__m) {
	 	.page-wrapper {
	 		padding: 3rem;

	 		.block-content {
	 			.actions-toolbar {
	 				.action.action-login.secondary {
	 					padding: 14px 17px;
	 				}
	 			}
	 		}
	 	}
	 }
    .opc-wrapper {
        .field-tooltip.toggle {
            display: none;
        }
    }
/*    .modal-custom.opc-sidebar.opc-summary-wrapper.custom-slide {
        width: 100%;
    }*/
    @include min-screen($screen__m) {
        .opc-wrapper {
            width: 100%;

            .checkout-shipping-address,
            .checkout-shipping-method {
                .step-title {
                    border: none;
                }
            }

            .checkout-shipping-method {
                padding: 2rem;
            }
        }
        .modal-custom.opc-sidebar.opc-summary-wrapper.custom-slide {
            width: 50%;
        }
    }  

        @include min-screen($screen__l) {
        .opc-wrapper {
            width: 65%;
        }
        .modal-custom.opc-sidebar.opc-summary-wrapper.custom-slide {
            width: 35%;
        }
    }  
    .checkout-payment-method .payment-method-content > .actions-toolbar > .primary .action.primary {
    	padding: 14px 17px;
    }
}
.checkout-onepage-success{
    .page-wrapper{
        .page-main{
            .columns{
                .main{
                    .checkout-success{
                        p{
                            &:nth-child(2){
                                font-size: 1.8rem;
                           }
                        }           
                    }
                }
                
                .action.primary{
                    @include primary-button($dark-blue);
                }
            }
        }
    }
}
// Account pages
//------------------------

.account.sales-order-history,
.account.sales-order-view,
.account.wishlist-index-index,
.account.downloadable-customer-products,
.account.customer-address-index,
.account.customer-account-edit,
.account.review-customer-index,
.account.newsletter-manage-index,
.account.customer-address-form,
.customer-account-index {
    @include max-screen($screen__m) {
        .page-main {
            padding-top: 0;
        }
    }
    .block.account-nav {
        .nav.items {
            display: block;
        }
    }
}

.customer-account-login {
    .block.block-new-customer {
        display: none;
    }
}

/* Custom form email template*/

.Bs.nH.iY.bAt {
    tr {
        .Bu.bAn {
            .nH.if {
                .nH.V8djrc.byY {
                    .nH {
                       .ha {
                            .hP {
                                 &:before {
                                    content: "JBL " !important;
                                }
                            }
                       } 
                    }
                }
            }
        }
    }
}

/* Checkout Purchase Number field*/

.checkout-index-index {
    .payment-method._active {
        #purchaseorder-form {
            .fieldset.payment.method {
                .field.field-number.required {
                    label {
                        visibility: hidden;
                    }
                    .control {
                        #po_number {
                            visibility: hidden !important;
                        }
                    }
                }
            }
        }
    }
}