body {
    font-family: Univers-lt-std-black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Univers-lt-std-black;
}

p,
div.value {
    font-family: Univers-lt-std-light;
}

.page-wrapper {
    .page-header {
        margin-bottom: 0;
    }
    .header.content {
        .jbl-custom-menu__mobile {
            display: none;
        }
        .jbl-custom-menu {
            .jbl-custom-menu__list {
                display: none;
            }
        }
        >li,
        .jbl-custom-menu__item-euro,
        .jbl-custom-menu__item-request {
            display: none;
        }
        .minicart-wrapper {
            margin-top: 0;
        }
    }
    @include max-screen($screen__m) {
        .minicart-wrapper {
            .action.showcart {
                span {
                    display: none;
                }
            }
        }
    }
    .minicart-wrapper {
        .action.showcart:hover {
            color: $dark-blue;
        }
    }
    @include min-screen($screen__m) {
        .page-header {
            background: #f5f5f5;
            .panel.wrapper {
                .panel.header {
                    .header.links {
                        visibility: hidden;
                    }
                }
            }
            .header.content {
                padding: 3rem 6rem 0;
                text-align: right;
                display: block;
                .jbl-custom-menu__mobile {
                    display: none;
                }
                .jbl-custom-menu {
                    margin-top: -0.7rem;
                    display: inline-block;
                    .jbl-custom-menu__list {
                        display: inline-flex;
                    }
                }
                .jbl-custom-menu__item {
                    display: inline-block;
                    .jbl-custom-menu__link {
                        color: $header-links_color;
                        font-size: 0.9rem;
                        font-family: Univers-lt-std-black;
                    }
                    &:after {
                        content: "|";
                        padding: 0 0.4rem;
                        font-family: Univers-lt-std-light;
                        color: $header-links_color;
                    }
                }
                >li {
                    display: inline-block;
                    margin-top: -0.7rem;
                    list-style: none;
                    a {
                        color: $header-links_color;
                        font-size: 0.9rem;
                    }
                    &:after {
                        content: "|";
                        padding: 0 0.4rem;
                        font-family: Univers-lt-std-light;
                        color: $header-links_color;
                    }
                }
                .block.block-search {
                    margin-top: -0.8rem;
                    width: 15rem;
                    order: 8;
                    .block.block-content {
                        #search_mini_form {
                            .control {
                                #search {
                                    border: 2px solid $header-links_color;
                                    font-family: Univers-lt-std-black;
                                    &::-webkit-input-placeholder {
                                        /* WebKit browsers */
                                        color: $header-links_color;
                                        font-family: Univers-lt-std-black;
                                        /*font-weight: 700;*/
                                    }
                                    &:-moz-placeholder {
                                        /* Mozilla Firefox 4 to 18 */
                                        color: $header-links_color;
                                        font-family: Univers-lt-std-black;
                                        /*font-weight: 700;*/
                                    }
                                    &::-moz-placeholder {
                                        /* Mozilla Firefox 19+ */
                                        color: $header-links_color;
                                        /*font-weight: 700;*/
                                        font-family: Univers-lt-std-black;
                                    }
                                    &:-ms-input-placeholder {
                                        /* Internet Explorer 10+ */
                                        color: $header-links_color;
                                        /*font-weight: 700;*/
                                        font-family: Univers-lt-std-black;
                                    }
                                }
                                .nested {
                                    display: none;
                                }
                            }
                            .actions {
                                .action.search {
                                    &:before {
                                        color: $header-links_color;
                                    }
                                }
                            }
                        }
                    }
                }
                .minicart-wrapper {
                    /*						order: 6;*/
                    margin-left: 0;
                    .action.showcart {
                        margin-top: 0.6rem;
                        position: relative;
                        top: -0.8rem;
                        font-size: 0.9rem;
                        /*							.text {
								content: "My shopping cart";
								position: relative;
								bottom: 1.3rem;
								font-size: 0.9rem;
								color: $header-links_color;
							}*/
                        .counter.qty {
                            display: inline;
                            position: relative;
                            top: 0;
                            background: transparent;
                            color: $header-links_color;
                            &:before,
                            &:after,
                            .counter-number {
                                font-size: 0.9rem;
                            }
                            .counter-number {
                                color: $header-links_color;
                            }
                        }
                        .counter.qty.empty {
                            display: none;
                        }
                        &:before {
                            display: none;
                            color: $header-links_color;
                        }
                        .total-euro {
                            &:before {
                                content: "|";
                                padding: 0 1rem 0 .4rem;
                                font-family: Univers-lt-std-light;
                                color: $header-links_color;
                            }
                        }
                    }
                }
            }
        }
    }
    @media (min-width: 768px) and (max-width: 820.98px) {
        .page-header {
            .header.content {
                padding: 3rem 4rem 0;
                .block.block-search {
                    margin-left: 1rem;
                }
            }
        }
    }
    //=====
    @include min-screen($screen__l) {
        .page-header {
            .header.content {
                padding: 3rem 8rem 0;
                .jbl-custom-menu__item {
                    .jbl-custom-menu__link {
                        font-size: 1.5rem;
                    }
                    &:after {
                        padding: 0 .8rem;
                    }
                }
                >li {
                    a {
                        font-size: 1.5rem;
                    }
                    &:after {
                        padding: 0 .8rem;
                    }
                    &:nth-child(9) {
                        &:before/*, &:after*/
                        {
                            padding: 0 0.8rem;
                        }
                    }
                }
                .block.block-search {
                    width: 16rem;
                    margin-left: 2rem;
                }
                .minicart-wrapper {
                    .action.showcart {
                        /*.text {*/
                        font-size: 1.5rem;
                        /*}*/
                        .counter.qty {
                            top: 0;
                            &:before,
                            &:after,
                            .counter-number {
                                font-size: 1.5rem;
                            }
                        }
                    }
                }
            }
        }
    }
    @media (min-width: 1024px) and (max-width: 1110px) {
        .page-header {
            .header.content {
                padding: 3rem 3rem 0;
            }
        }
    }
    //======
    @include min-screen($screen__m) {
        .sections.nav-sections {
            display: none;
        }
    }
    .home-slider,
    .category-banner {
        img {
            width: 100%;
            max-width: 100%;
        }
    }
    .page-title-wrapper {
        display: none;
    }
    #maincontent {
        .column.main {
            .toolbar.toolbar-products {
                display: none;
            }
        }
        .sidebar.sidebar-main {
            padding-right: 1%;
            .block.filter {
                display: none;
            }
        }
        .sidebar.sidebar-additional {
            display: none;
        }
    }
    .home-links,
    .page-bottom {
        margin: 0;
        margin-top: 1rem;
        padding: 0;
        width: 100%;
        max-width: 100%;
        .navigation {
            display: block;
            background: #fff;
            text-align: center;
            max-width: 100%;
            ul {
                padding: 0;
                .level0 {
                    margin: 0;
                    padding: 3rem;
                    width: 100%;
                    border-top: none;
                    &.active {
                        .level-top {
                            -webkit-filter: grayscale(0%);
                            /* Safari 6.0 - 9.0 */
                            filter: grayscale(0%);
                            border: none;
                            &.active {
                                border: none;
                            }
                        }
                    }
                    .level-top {
                        width: 100%;
                        -webkit-filter: grayscale(100%);
                        /* Safari 6.0 - 9.0 */
                        filter: grayscale(100%);
                        padding: 0;
                        &:hover {
                            -webkit-filter: grayscale(0%);
                            /* Safari 6.0 - 9.0 */
                            filter: grayscale(0%);
                        }
                        span {
                            display: none;
                        }
                    }
                    &:nth-child(2) {
                        .level-top {
                            display: none;
                            content: url(../images/NavigatiebalkHarman.png);
                        }
                    }
                    &:nth-child(3) {
                        .level-top {
                            content: url(../images/NavigatiebalkJBL.png);
                        }
                    }
                    &:nth-child(4) {
                        .level-top {
                            display: none;
                            content: url(../images/NavigatiebalkHarmanKardon.png);
                        }
                    }
                    &:nth-child(1) {
                        .level-top {
                            display: none;
                        }
                    }
                    &:nth-child(5) {
                        .level-top {
                            display: none;
                        }
                    }
                }
                .level0.has-active>.level-top {
                    border: none;
                    filter: grayscale(0%);
                }
            }
        }
        .space-before-footer {
            width: 100%;
            min-height: 8rem;
            background: $blue;
        }
    }
    @include max-screen($screen__m) {
        .home-links,
        .page-bottom {
            .navigation {
                ul {
                    .level0 {
                        &:nth-child(1) {
                            .level-top {
                                display: none;
                            }
                        }
                        &:nth-child(5) {
                            .level-top {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
    @include min-screen($screen__m) {
        .home-links,
        .page-bottom {
            .navigation {
                ul {
                    .level0 {
                        padding: 0 .3rem;
                        width: 19.5%;
                        &:nth-child(1) {
                            .level-top {
                                content: url(../images/NavigatiebalkBlank.png);
                            }
                        }
                        &:nth-child(5) {
                            .level-top {
                                content: url(../images/NavigatiebalkBlank.png);
                            }
                        }
                    }
                }
            }
        }
    }
    .nav-sections {
        .header.links {
            li.greet.welcome {
                display: none;
            }
        }
    }
    .home-links {
        .navigation {
            ul {
                .level0 {
                    .level-top {
                        position: relative;
                        // top: -50px;
                    }
                }
            }
        }
    }
    .breadcrumbs {
        margin-top: 20px;
        margin-bottom: 0;
        .items {
            .item {
                a,
                strong {
                    font-family: Univers-lt-std-light;
                    text-transform: uppercase;
                    font-size: 1.6rem;
                    color: #a3a3a3;
                }
            }
        }
    }
    .page-footer {
        .footer.content {
            border: none;
            .block.newsletter,
            .footer.links,
            .bugs,
            .copyright {
                display: none;
            }
            .cms-footer {
                margin-bottom: 3rem;
                text-align: center;
                .cms-copyright {
                    color: white;
                }
            }
        }
    }
}

.page-main {
    margin-top: 2rem;
}

// Buttons
//--------------------
.abs-button-l,
.bundle-actions .action.primary,
.bundle-options-container .block-bundle-summary .box-tocart .action.primary,
.product-info-main .box-tocart .action.tocart,
.product-options-bottom .box-tocart .action.tocart,
.methods-shipping .actions-toolbar .action.primary,
.block-authentication .action.action-register,
.block-authentication .action.action-login,
.checkout-payment-method .payment-method-content>.actions-toolbar>.primary .action.primary,
.form-address-edit .actions-toolbar .action.primary,
.multicheckout .action.primary,
.action.create.primary,
.action.login.primary,
.action.primary.add,
.action.primary.continue,
.action.submit.primary,
.action.save.primary {
    @include primary-button($secondary-color);
}

// Links
//-----------------------------------
a,
a a,
li a,
a:visited,
.alink:visited {
    color: $secondary-color;
}