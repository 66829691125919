.cms-home {
	.home-slider {
		margin-bottom: 0;

		.slide-wrapper {
			position: relative;

			.slide-text-wrap {				
				width: 100%;			
				position: absolute;
				top: 50%;
				transform: translateY(-50%);

				.slide-text {
					max-width: 1280px;
					margin: 0 auto;
					padding: 2rem;
					h1 {
						margin-bottom: 0;
						color: white;
						font-size: 1.5rem;

						span {
							font-size: 2rem;
						}
					}
					> a {
						&:hover {
							text-decoration: none;
						}
						h3 {
							width: 100%;
							margin-top: .8rem;
							padding: .7rem 2rem;
							text-align: center;
							font-size: 1rem;
							color: white;					
						}
					}
					&.jbl {
						h3 {
							background: $primary-button_background-color;
						}
					}
					&.harman {
						h1 { 
							span {
								color: $primary-color-harman;
							}
						}
						h3 {
							background: $primary-button_background-color-harman;
						}
					}
					&.harman-kardon {
						h3 {
							background: $primary-button_background-color-harman-kardon;
						}
					}
				}
			}
		}

		.slick-prev,
		.slick-next {
			width: 59px;
			border: none;
			&:before {
				background: $secondary-color;
				border: 1px solid white;
				opacity: 1;
			}
		}

		.slick-prev {			
			left: 2%;			
			z-index: 1;
			&:before {
				content: url(../images/arrow-89-24.ico);
				padding: 15px 9px 5px 6px;
			}
		}

		.slick-next {
			right: 2%;
			&:before {
				content: url(../images/arrow-25-24.ico);
				padding: 15px 6px 5px 9px;
			}
		}
	}
	@include min-screen($screen__m) {
		.home-slider {
			margin-bottom: 30px;

			.slide-wrapper {			
				.slide-text-wrap {				
					.slide-text {
						padding: 7rem;
						h1 {
							font-size: 4rem;
							span {
								font-size: 6.5rem;
								text-shadow: 3px 5px 6px #464646;
							}
						}
						> a {
							h3 {
								width: 40%;
								padding: 1rem 2rem;
								margin-top: 1.5rem;
								font-size: 1.3rem;					
							}
						}
					}
				}
			}
		}
	}

	@include min-screen($screen__l) {
		.home-slider {
			.slide-wrapper {
				.slide-text-wrap {
					.slide-text {
						h1 {
							font-size: 4.5rem;
						}
						> a {
							h3 {
								font-size: 1.8rem;
							}
						}
					}
				}
			}
		}
	}

	.column.main {
		min-height: 50px;
	}

	.page-bottom {
		.navigation {
			display: none;
		}
	}
}