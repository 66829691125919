@mixin primary-button($background) {
    color: white;
    background: $background;    
    text-transform: uppercase;
    font-family: Univers-lt-std-light;
    font-size: 1.5rem;
    font-weight: 700;
    padding: 0.7rem 10rem;
    border: none;
    border-radius: unset;

    :hover {
        opacity: 0.8;
    }
}